import React from "react"
import ReactModal from "react-modal"
import { Formik, ErrorMessage, Field, Form } from "formik"
import * as Yup from "yup"
import axios from "axios"
import ReactGA4 from "react-ga4"

import { BACKEND_URL } from "gatsby-env-variables"

import contact from "../../assets/images/contact2.png"

ReactModal.setAppElement(`#___gatsby`)

const InitializeGoogleAnalytics = () => {
  // Initialize GA4 - Add your measurement ID
  ReactGA4.initialize(process.env.GOOGLE_ANALYTICS_ID)
  // console.log("GA INITIALIZED:", process.env.GOOGLE_ANALYTICS_ID)
}
InitializeGoogleAnalytics()

const ContactForm = () => {
  // Define Form Shema for client side validation.
  const formSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Must be 2 characters or more")
      .max(15, "Must be 15 characters or less")
      .required("Required"),
    surname: Yup.string()
      .min(2, "Must be 2 characters or more")
      .max(20, "Must be 20 characters or less")
      .notRequired(),
    email: Yup.string()
      .email("Invalid email")
      .min(4, "Must be 4 characters or more")
      .max(100, "Must be 100 characters or less")
      .required("Required"),
    phone: Yup.string()
      .matches(
        "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$",
        "Invalid phone number, formt : (+XX) XXXXXX"
      )
      .min(5, "Must be 5 characters or more")
      .max(20, "Must be 20 characters or less")
      .notRequired(),
    website: Yup.string()
      .matches(
        "^(http|https)://[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?$",
        "Please use http:// or https:// proper URL"
      )
      .min(5, "Must be 5 characters or more")
      .max(20, "Must be 20 characters or less")
      .notRequired(),
    // Could use url but this would allow also other protocols like ftp://, so no
    // website: Yup.string().url("Please use http:// or https:// proper URL"),
    message: Yup.string()
      .min(5, "Must be 5 characters or more")
      .max(600, "Maximum message lendth is 600 characters.")
      .required("Please provide your message "),
  })

  const sendMessage = contact => {
    const req = {
      Name: contact.name,
      Surname: contact.surname,
      email: contact.email,
      phone: contact.phone,
      message: contact.message,
      source: "contact",
    }

    const headers = { "Content-type": "application/json" }
    axios
      .post(BACKEND_URL + "/contact-messages", req, { headers })
      .then(response => {
        // Handle success.
        // console.log("Data: ", response.data)
      })
      .catch(error => {
        // Handle error.
        if (error.response) {
          console.log("An error occurred:", error.response.data)
        } else {
          console.log("An error occurred: no data returned from server")
        }
      })
  }

  const initalContactFormValues = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    website: "",
    message: "",
  }

  const modalStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      zIndex: "10",
    },
    content: {
      position: "relative",
      top: "auto",
      left: "auto",
      right: "auto",
      bottom: "auto",
      maxWidth: "500px",
      margin: "32px auto",
      padding: 0,
      border: 0,
    },
  }

  const [confirmIsOpen, setConfirmIsOpen] = React.useState(false)

  const openConfirm = values => setConfirmIsOpen(true)
  const closeConfirm = event => setConfirmIsOpen(false)

  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Let's Get in Touch</span>
          <h2>Send Us A Message!</h2>
          <p>
            Your email address will not be published. Required fields are marked
            *
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <Formik
                initialValues={initalContactFormValues}
                validationSchema={formSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  setTimeout(() => {
                    ReactGA4.event({
                      category: "form_send",
                      action: "send_contact",
                    })
                    ReactGA4.ga("event", "conversion", {
                      send_to:
                        process.env.GOOGLE_TAG_ID + "/q2oNCNSPzLAYEKr1_5wB",
                    })
                    sendMessage(values)
                    setSubmitting(false)
                    openConfirm(values)
                    resetForm()
                  }, 400)
                }}
              >
                <Form>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Name*"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="name" />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <Field
                          type="text"
                          name="surname"
                          className="form-control"
                          placeholder="Surname*"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="surname" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-6">
                      <div className="form-group">
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Company email address*"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="email" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <Field
                          type="text"
                          name="phone"
                          className="form-control"
                          placeholder="Contact phone number"
                        />
                        <div className="validation-danger">
                          <ErrorMessage name="phone" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <Field name="message">
                          {({
                            field, // { name, value, onChange, onBlur }
                            form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                          }) => (
                            <textarea
                              name="message"
                              className="form-control"
                              cols="30"
                              rows="6"
                              placeholder="Your reason for contact"
                              {...field}
                            />
                          )}
                        </Field>
                        <div className="validation-danger">
                          <ErrorMessage name="message" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-12 col-md-12">
                      <button type="submit" className="default-btn">
                        <i className="flaticon-tick"></i>
                        Send Message <span></span>
                      </button>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
      <ReactModal
        isOpen={confirmIsOpen}
        onRequestClose={closeConfirm}
        contentLabel="Confirm Your Data"
        style={modalStyles}
        className="modal-dialog modal-dialog-centered modal-lg"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="staticBackdropLabel">
              Message Sent
            </h5>
            <button
              type="button"
              className="btn-close"
              onClick={closeConfirm}
              aria-label="close"
            ></button>
          </div>
          <div className="modal-body">
            Thank you for sending us your message. <br />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={closeConfirm}
            >
              OK
            </button>
          </div>
        </div>
      </ReactModal>
    </section>
  )
}

export default ContactForm
