import React from "react"

const ContactForm2 = () => {
  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">Let's Get in Touch</span>
          <h3 className="mb-4">Send Us A Message!</h3>
          <a
            href="https://form.jotform.com/232323552403344"
            className="default-btn"
            target="_blank"
          >
            <i className="flaticon-tick"></i>
            Contact Form <span></span>
          </a>
        </div>
      </div>
    </section>
  )
}

export default ContactForm2
